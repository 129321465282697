<template>
    <div style="text-align: center">
        <el-row :gutter="20">
            <el-col class="point" :span="6" v-for="(item,index) in tableData" :key="index" @click="$router.push({path:'/client/server',query:{clientId:item.id}})" style="min-width: 300px;margin-top:10px">
                <div class="shadow">
                    <div class="title">{{item.name}}(<span class="success" v-if="item.healthState===1">在线</span><span class="error" v-else>离线</span>)</div>
                    <div class="data">
                        <div class="line">
                            <span class="label">运行中 </span><span class="success">{{item.onlineCount?item.onlineCount:0}}</span>&nbsp;&nbsp;<span
                            class="label">全部 </span><span>{{item.totalCount?item.totalCount:0}}</span>
                        </div>
                        <div class="line" v-if="item.lowDisk">
                            <span class="label">{{item.lowDisk.name}}&nbsp; 剩余:<span :class="{success:item.lowDisk.free/1024/1024/1024>5,error:item.lowDisk.free/1024/1024/1024<=5}">
                            {{(item.lowDisk.free/1024/1024/1024).toFixed(2)}}G</span>&nbsp;/&nbsp;<span>总计:{{(item.lowDisk.total/1024/1024/1024).toFixed(2)}}G</span></span>&nbsp;
                            <el-popover placement="right" :width="300" trigger="hover">
                                <template #reference>
                                    <i class="el-icon-pie-chart" @click="showOtherDisk(item)" style="font-size: 14px"></i>
                                </template>
                                <div v-for="(it,index) in item.diskInfo" :key="index">{{it.name}}&nbsp; 剩余:<span :class="{success:it.free/1024/1024/1024>5,error:it.free/1024/1024/1024<=5}">
                                    {{(it.free/1024/1024/1024).toFixed(2)}}G</span>&nbsp;/&nbsp;<span>总计:{{(it.total/1024/1024/1024).toFixed(2)}}G</span></div>
                            </el-popover>


                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";

    export default {
        name: "ClientReport",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                editVisible: false,
                authVisible: false,
                form: {},
                authForm: {
                    Client: null,
                    checkedAuth: [],
                },
                auth: [],
                pageTotal: 0,
                query: {
                    role: '',
                    name: null,
                    username: null,
                    state: null,
                    page: 1,
                    rows: 200
                },
            };
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                http.post("/client/list", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.getClientServer();
                        for (let i = 0; i < this.tableData.length; i++) {
                            if(this.tableData[i].disk){
                                let disk = JSON.parse(this.tableData[i].disk);
                                this.tableData[i].diskInfo = disk;
                                if(disk&&disk.length>0){
                                    let lowDisk = null;
                                    for (let j = 0; j < disk.length; j++) {
                                        if(lowDisk==null||lowDisk.free>disk[j].free){
                                            lowDisk = disk[j];
                                        }
                                    }
                                    this.tableData[i].lowDisk = lowDisk;
                                }
                            }
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取客户服务
            getClientServer(){
                http.post("/client/server/stat", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        for (let i = 0; i < data.length; i++) {
                            let obj = common.arrayFindReturnObj(this.tableData,data[i].clientId);
                            if(obj){
                                obj.onlineCount = data[i].onlineCount;
                                obj.totalCount = data[i].totalCount;
                            }
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //跳转服务
            goServer(obj) {
                this.$router.push({path: "/client/server", query: {clientId: obj.id}});
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "ClientReport") {
                    //初始化
                    this.getData();
                }
            }
        },
    };
</script>

<style scoped>

    .table {
        width: 100%;
        font-size: 14px;
    }
    .shadow{
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        height:120px;
        line-height: 120px;
        text-align: center;
        position: relative;
    }
    .shadow .title{
        position: absolute;
        line-height: 40px;
        text-align: center;
        width: 100%;
        top: 0;
        left: 0;
        height: 40px;
        font-size: 20px;
        color: #555;
        font-weight: bold;
        background: rgba(0,0,0,0.05);
    }
    .shadow .data{
        position: absolute;
        top: 50px;
        left: 0;
        height:30px;
        max-height: 60px;
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
    }
    .data .label{
        font-size: 14px;
        color: #555;
        font-weight: normal;
    }
    .data .line{
        padding: 5px;
        line-height: normal;
    }
</style>
