<template>
    <div class="container" style="padding: 5px 15px;">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-card shadow="hover">
                    <div class="user-info">
                       <!-- <img src="../../assets/img/img.png" class="user-avator" alt/>-->
                        <div class="user-info-cont">
                            <div class="user-info-name">{{ user.name }}</div>
                            <div>{{findArray(roles,user.role)||user.role}}</div>
                        </div>
                    </div>
                    <div class="user-info-list">
                        上次登录时间：<span v-if="log.createDate">{{log.createDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        <span></span>
                    </div>
                    <div class="user-info-list">
                        上次登录ip：{{log.ip}}
                        <span></span>
                    </div>
                    <div class="user-info-list">
                        上次登录地：{{log.address}}
                        <span></span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 5px">
            <el-col :span="24" v-if="hasAuth('GetClient')">
                <el-card shadow="hover">
                    <ClientReport/>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 5px">
            <el-col :span="24" v-if="hasAuth('GetInformLog')">
                <el-card shadow="hover">
                    <InformLogReport/>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    import http from "../../utils/http";
    import common from "../../utils/common";
    import api from "../../utils/api";
    import ClientReport from "../client/ClientReport";
    import InformLogReport from "../inform/InformLogReport";
    export default {
        name: "dashboard",
        components: {ClientReport,InformLogReport},
        data() {
            return {
                hasAuth: common.hasAuth,
                log: {},
                roles: [],
                orgIds: [],
            }
        },
        created() {
            //获取最后一次登陆
            this.getLastLogin();
            http.post("/role/list").then(data => {
                data = data.data;
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        data[i].id = data[i].role;
                    }
                    this.roles = data;
                }
            })
            //获取部门
            api.getBase("org", data => {
                this.orgIds = data;
            });
        },
        computed: {
            role() {
                return this.$store.state.user.role === 'admin' ? "管理员" : "业务员"
            },
            user() {
                return this.$store.state.user;
            },
            collapse() {
                return this.$store.state.collapse;
            }
        },
        methods: {
            //最后一次登陆
            getLastLogin() {
                http.post("/log/getPrevLogin").then(res => {
                    this.log = res.data;
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            //获取显示宽度
            getSpan(span){
                let count = 0;
                if(this.hasAuth('GetNotice')){
                    count++;
                }
                if(count===0){
                    return span;
                }
                return 24/count;
               // return Math.floor(count*span);
            }
        }
    };
</script>

<style scoped>

    .user-info {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 2px solid #ccc;
        margin-bottom: 20px;
    }

    .user-avator {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    .user-info-cont {
        padding-left: 50px;
        flex: 1;
        font-size: 14px;
        color: #999;
    }

    .user-info-cont div:first-child {
        font-size: 30px;
        color: #222;
    }

    .user-info-list {
        font-size: 14px;
        color: #999;
        line-height: 25px;
    }

    .mgb20 {
        margin-bottom: 20px;
    }

    .notice .el-card__body {
        padding: 0 !important;
    }

    .el-col {
        padding: 1px !important;
    }
</style>
