<template>
    <div class="">
        <el-table
                :data="tableData"
                border
                class="table"
                header-cell-class-name="table-header">
            <el-table-column prop="id" label="未处理异常记录" width="55" align="left">
                <el-table-column prop="id" label="序号" width="55" align="center">
                    <template #default="scope">
                        <span>{{scope.$index + 1}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型" width="150px" align="center">
                    <template #default="scope">
                        {{findArray(types,scope.row.type)}}
                    </template>
                </el-table-column>
                <el-table-column prop="clientId" label="客户端" width="100px" align="center">
                    <template #default="scope">
                        <span>{{common.arrayFindReturn2(clients,scope.row.clientId,"id","name,ip")}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="serverId" label="服务" width="100px" align="center">
                    <template #default="scope">
                        <span>{{common.arrayFindReturn(servers,scope.row.serverId)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="内容" align="center"></el-table-column>
                <el-table-column prop="state" label="状态" width="100px" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.state===1">已处理</span>
                        <span v-else class="error">未处理</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="180px" align="center">
                    <template #default="scope">
                        {{scope.row.createDate.toDate('yyyy-MM-dd HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" class-name="table-op" width="80" align="center"
                                 v-if="hasAuth('UpdateInformLog')">
                    <template #default="scope">
                        <div >
                            <el-button v-if="hasAuth('UpdateInformLog') && scope.row.state===0"
                                       type="text"
                                       icon="el-icon-s-flag"
                                       @click="handle(scope.$index, scope.row)"
                            >处理</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";

    export default {
        name: 'tabs',
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                query: {
                    state: 0,
                    page: 1,
                    rows: 5
                },
                users: [],
                tableData: [],
                pageTotal: 0,
                datetime: [],
                types: [],
                servers: [],
                clients: [],
            }
        },
        created() {
            this.getData();
            this.getType();
            this.getServer();
        },
        methods: {
            //获取操作类型
            getType() {
                http.post("/inform/log/type").then(data => {
                    data = data.data;
                    if (data) {
                        this.types = data;
                    }

                })
            },
            // 获取登陆日志
            getData() {
                this.query.startDate = null;
                this.query.endDate = null;
                if (this.datetime && this.datetime.length === 2) {
                    this.query.startDate = this.datetime[0].getTime();
                    this.query.endDate = this.datetime[1].getTime();
                }
                http.post("/inform/log/list", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                        if (this.pageTotal > 0) {
                            this.getClientByIds(common.getIds(this.tableData, "clientId"));
                        }
                    }

                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取客户端
            getClientByIds(ids) {
                http.post("/client/get/ids", ids, {
                    headers: {"Content-Type": 'application/json;charset=utf-8'},
                    animation: store.state.Animation.NONE
                }).then(data => {
                    data = data.data;
                    if (data) {
                        this.clients = data;
                    }
                })
            },
            //获取服务
            getServer() {
                http.post("/server/list").then(data => {
                    data = data.data;
                    if (data) {
                        this.servers = data;
                    }
                })
            },
            //获取客户端
            searchClient(param, callback) {
                if (!(param instanceof Object)) {
                    param = {key: param};
                }
                http.post("/client/list", param, {animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        if (callback) {
                            callback(data);
                            this.clients.push(data)
                            return;
                        }
                        this.clients = data.rows;
                    }
                })
            },
            // 处理操作
            handle(index, row) {
                this.$confirm("确定标记为处理吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        http.post("/inform/log/update/state", {id: row.id}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success("操作成功");
                                this.getData();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                this.$confirm("确定要删除吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        http.post("/inform/log/delete", {id: row.id}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success("删除成功");
                                this.getData();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },

            // 分页导航
            handleChange(val) {
                this.query.page = val;
                this.getData();
            },
        },
    }

</script>

<style>
    .handle-box {
        margin-bottom: 20px;
    }


    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .handle-select {
        width: 120px;
    }

    .tree-input input {
        height: 30px;
        padding: 0;
        margin: 0;
    }
</style>